import * as React from 'react'
import { graphql } from 'gatsby'
import { useFormContext } from '../context/form.context'
import { useLangContext } from '../context/lang.context'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { content } from '../content/sklep'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { ProductListing } from '../components/ProductListing'
import CollectionLinks from '../components/shopify/collection-tags'
import Button from '../components/Button'
import { s } from '../style'

export default function Products({
  data: { products, translatedProducts, products2 },
}) {
  const { formOpen, setFormOpen } = useFormContext()
  const { lang } = useLangContext()

  const shopifyProductList = products.nodes
  const shopifyTranslatedProductList = translatedProducts.nodes
  const matchedTranslatedProducts = []

  shopifyTranslatedProductList.forEach((translatedProduct) => {
    const matchedProduct = shopifyProductList.find(
      product => product.storefrontId === translatedProduct.storefrontId
    )

    if (matchedProduct) {
      const images = matchedProduct.images
      const priceRangeV2 = matchedProduct.priceRangeV2
      const variants = matchedProduct.variants
      const metafields = matchedProduct.metafields

      const mergedProduct = {
        ...translatedProduct,
        images,
        priceRangeV2,
        variants,
        metafields,
      }

      matchedTranslatedProducts.push(mergedProduct)
    }
  })

  const contentfulProductList = products2.nodes.filter(
    (item) => item['orderNo'] !== null
  )

  const productList = matchedTranslatedProducts
    .map((shopifyProduct) => ({
      ...shopifyProduct,
      ...contentfulProductList.find(
        (contentfulProduct) => contentfulProduct.slug === shopifyProduct.handle
      ),
    }))
    .sort((a, b) => a.orderNo - b.orderNo)

  const buttonAction = () => setFormOpen(true)

  return (
    <Layout white>
      <Seo
        title={content.seo.title[lang]}
        desc={content.seo.desc[lang]}
        image={'ogimage.jpg'}
        url={content.seo.url[lang]}
      />
      <section css={sOuter}>
        <CollectionLinks />
        <h1 css={sHeadline}>{content.header.h1[lang]}</h1>
        <p css={sParagraph}>{content.header.desc[lang]}</p>
        <ProductListing products={productList} />
        <div css={sButtonWrapper}>
          <p
            css={sParagraph}
            dangerouslySetInnerHTML={{ __html: content.afterList.desc[lang] }}
          />
          <Button noMargin reactive action={buttonAction}>
            {content.afterList.button.text[lang]}
          </Button>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    products: allShopifyProduct {
      nodes {
        ...ProductCard
        storefrontId
        featuredImage {
          transformedSrc
        }
      }
    }
    translatedProducts: allShopifyTranslatedProduct {
      nodes {
        id
        title
        handle
        storefrontId
      }
    }
    products2: allContentfulProdukt(
      sort: { order: ASC, fields: orderNo }
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        slug
        orderNo
      }
    }
    productCollections: allShopifyCollection {
      nodes {
        id
        title
        description
        descriptionHtml
        storefrontId
        handle
      }
    }
    productTranslatedCollections: allShopifyTranslatedCollection {
      nodes {
        id
        title
        description
        descriptionHtml
        locale
        storefrontId
        handle
      }
    }
  }
`

const sOuter = {
  height: 'max-content',
  minHeight: '100vh',
  width: '100%',
  maxWidth: '1500px',
  position: 'relative',
  top: 0,
  left: 0,
  margin: '0 auto',
  padding: '12rem 2rem 10rem',
  [s.xs]: {
    padding: '8rem 2rem',
  },
}

const sParagraph = {
  lineHeight: '2em',
  fontSize: '1rem',
  opacity: '0.6',
  marginBottom: '3rem',
  [s.md]: {
    fontSize: '1.15rem',
    padding: '0 2rem',
  },
}

const sHeadline = {
  margin: '0 auto 2rem',
  fontSize: '3rem',
  [s.md]: {
    padding: '0 2rem',
  },
}

const sButtonWrapper = {
  width: '100%',
  marginTop: '8rem',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  p: {
    maxWidth: '800px',
  },
}
