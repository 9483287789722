export const content = {
  seo: {
    title: {
      pl: `Kontenery Morskie | Sklep z kontenerami morskimi`,
      en: `Sea Containers | Sea Container Store`,
      zh: `海洋集装箱 | 海洋集装箱商店`
    },
    desc: {
      pl: `✓ Kontenery Morskie ✓ Kontenery 20DV ✓ Kontener 40DV ✓Sklep z kontenerami ✓Szybka realizacja`,
      en: `✓ Maritime Containers ✓ 20DV Containers ✓ 40DV Container ✓ Container Store ✓ Fast Implementation`,
      zh: `海运集装箱 ✓ 20DV 集装箱 ✓ 40DV 集装箱 ✓ 集装箱仓库 ✓ 快速执行`
    },
    url: {
      pl: `sklep`,
      en: `shop`,
      zh: `shop`
    }
  },
  header: {
    h1: {
      pl: `Sklep`,
      en: `Shop`,
      zh: `店铺`
    },
    desc: {
      pl: `Przygotowaliśmy podstawową ofertę kontenerów dostępnych na naszych depotach. Są to kontenery z możliwością natychmiastowego zamówienia i krótkim terminem dostawy. W przypadku większych lub bardziej skomplikowanych zamówień zachęcamy do kontaktu z naszym ekspertem.`,
      en: `We have prepared a basic offer of containers available at our depots. These are containers that can be ordered immediately with a short delivery time. For larger or more complex orders, we encourage you to contact our expert.`,
      zh: `我们已备有部分基础款集装箱，仓库现货可发，可即刻订购。若订单较大或要求较复杂，建议您联系我们的专家。`
    }
  },
  afterList: {
    desc: {
      pl: `Jeśli Twoje zapotrzebowanie na kontenery jest bardziej złożone, zachęcamy do skorzystania z&nbsp;formularza kontaktowego na stronie. Po przesłaniu informacji, przydzielamy opiekuna do Twojego zapytania, który przygotuje ofertę dostosowaną do indywidualnych potrzeb Twojego biznesu.`,
      en: `If your container needs are more complex, we encourage you to use the contact form on our website. After receiving your information, we assign a representative to your inquiry who will prepare an offer tailored to the individual needs of your business.`,
      zh: `如果您的集装箱需求比较复杂，建议您填写我司网站上的联系表单来提交信息。收到您的信息后，我们会指派一名代表处理您的问询，并根据您企业的个性化需求为您量身定制产品/服务。`
    },
    button: {
      text: {
        pl: 'Kontakt z ekspertem',
        en: 'Query now',
        zh: '即刻查询',
      },
    }
  }
}