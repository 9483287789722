import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import { s, colors, alpha } from '../../style'
import { navStyle, navLink, activeLink} from "./collection-tags.module.css"

export default function CollectionLinks() {
  const { lang } = useLangContext()
  const results = useStaticQuery(graphql`
  {
    collections: allShopifyTranslatedCollection {
      nodes {
        id
        handle
        title
        locale
      }
    }
  }
`)

  const checkLang = lang === 'zh' ? 'zh-TW' : lang

  const collections = results.collections.nodes.filter((node) => node.locale === checkLang)

  // console.log(collections);

  const firstTag = {
    title: {
      pl: 'Wszystkie',
      en: 'All containers',
      zh: '所有集装箱'
    },
    link: {
      pl: `sklep`,
      en: `shop`,
      zh: `shop`
    }
  }

  return (
    <nav className={navStyle}>
      <Link
        key="All"
        className={navLink}
        to={`/${firstTag.link[lang]}/`}
        activeClassName={activeLink}
      >
        {firstTag.title[lang]}
      </Link>
      {collections.map(({id, title, handle}) => (
        <Link
          key={id}
          className={navLink}
          to={`/${firstTag.link[lang]}/${handle}`}
          activeClassName={activeLink}
        >
          {title}
        </Link>
      ))}
    </nav>
  )
}